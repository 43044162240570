import React from 'react'
import Layout from 'src/components/Layout'
import PageHeader from 'src/components/PageHeader'
import { t } from 'src/i18n/translation/t'
import { PageContext } from 'src/page/types'
import Heading from '../../components/privacy-policy/Heading'
import Container from '../../components/Container'

type SectionProps = {
    heading: string
    text: string
}
const CookiesPage = ({ pageContext }: { pageContext: PageContext }) => {
    const { msgs } = pageContext
    return (
        <Layout pageCtx={pageContext}>
            <PageHeader>{t(msgs.cookies.title)}</PageHeader>
            <Container>
                {msgs.cookies.sections.map((section: SectionProps, index) => (
                    <div key={index}>
                        <Heading text={section.heading} />
                        <p>{section.text}</p>
                    </div>
                ))}
            </Container>
        </Layout>
    )
}
export default CookiesPage
